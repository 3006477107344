import React from 'react';
import { StyleSheet, View, FlatList } from 'react-native'
import { useInfiniteHits } from 'react-instantsearch-hooks'


export default function SearchResults(props) {
  const { hits, isLastPage, showMore } = useInfiniteHits(props);

  return (
    <FlatList
      data={hits}
      keyExtractor={(item) => item.objectID}
      // ItemSeparatorComponent={() => <View style={styles.separator} />}
      // onEndReached={() => {
      //   if (!isLastPage) {
      //     showMore();
      //   }
      // }}
      renderItem={props.renderItem}
    />
  )
}

const styles = StyleSheet.create({
  separator: {
    borderBottomWidth: 1,
    borderColor: '#ddd',
  },
  item: {
    padding: 18,
  },
  dateTimeView: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginRight: 4,
  },
  itemTitle: {
    marginRight: 25,
  },
});
