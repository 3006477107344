import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

export default function HeaderIconButton( { iconName, onPress } ) {
  return (
    <TouchableOpacity onPress={onPress} style={styles.icon}>
      <View style={styles.view}>
        <Ionicons name={iconName} size={24} color='#000000' />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  view: {
    height: 24,
    width: 24,
  },
  icon: {
    marginHorizontal: 14,
    marginVertical: 6
  }
})
