
export const firestoreFBConfig = {
  apiKey: "AIzaSyB6d_bqMkEO3ABiLh8y2uGHwG_vxnQRT1o",
  authDomain: "qarasay-kz.firebaseapp.com",
  databaseURL: "https://qarasay-kz.firebaseio.com",
  projectId: "qarasay-kz",
  storageBucket: "qarasay-kz.appspot.com",
  messagingSenderId: "572798335623",
  appId: "1:572798335623:web:61bd12e1d2619f5b7fd622",
  measurementId: "G-CGB6P6N0GL"
}

export const authFBConfig = {
  apiKey: "AIzaSyDKPl284Bict2Ae9wuyeC4O3UbNpPYEH2k",
  authDomain: "market-atlas-c.firebaseapp.com",
  projectId: "market-atlas-c",
  storageBucket: "market-atlas-c.appspot.com",
  messagingSenderId: "574793834823",
  appId: "1:574793834823:web:12d510a90225441b6f6c62"
};
