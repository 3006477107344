import React, { useEffect, useContext, useState }  from 'react'
import { ScrollView, View, StyleSheet, Image } from 'react-native'
import Text from '../../../components/Text'
import TextInput from '../../../components/TextInput'
import Button from '../../../components/Button'
import colors from '../../../utils.js/colors'
import { Ionicons } from '@expo/vector-icons'
import { UserContext } from '../../../global/UserProvider'

export default function WelcomeScreen( { navigation } ) {
  
  const [context, setContext] = useContext(UserContext)

  return (
    <ScrollView>
      <View style={{padding: 20, margin: 20,  borderRadius: 20, backgroundColor: colors.primary}}>
        {/* <Text category='h1' style={{marginBottom: 20, color: 'white', textAlign: 'center'}}>Добро пожаловать в Qarasaÿ!</Text> */}
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30}}>
          <Image source={require('../../../public/logo_white.png')} resizeMode='contain' style={{
            height: 40,
            width: 100,
            }} 
          />
          <Image source={require('../../../public/made_in_almaty.png')} resizeMode='contain' style={{
            height: 60,
            width: 50,
           }} 
          />
        </View>
        <View style={styles.row}>
          <Image source={require('../../../public/leaf.png')} resizeMode='contain' style={{flex: 1,
            height: 40,
            width: 40,
            alignSelf: 'center',
            marginRight: 20,}} 
          />
          <Text category='s1' style={styles.rowText}>У нас все натуральное - без консервантов и химии</Text>
        </View>
        <View style={styles.row}>
          <Image source={require('../../../public/almaty.png')} resizeMode='contain' style={styles.image} />
          <Text category='s1' style={styles.rowText}>90% продуктов из Алматы и Алматинской области</Text>
        </View>
        <View style={styles.row}>
          <Image source={require('../../../public/dumpling_on_skate_white.png')} resizeMode='contain' style={styles.image} />
          <Text category='s1' style={styles.rowText}>Каждый день мы привозим все самое свежее</Text>
        </View>
        <View style={styles.row}>
          <Image source={require('../../../public/heart.png')} resizeMode='contain' style={{flex: 1,
            height: 40,
            width: 40,
            alignSelf: 'center',
            marginRight: 20,}} />
          {/* <Ionicons name='heart-outline' size={60} color='white' /> */}
          <Text category='s1' style={styles.rowText}>Все продукты мы выбираем как для себя и своих родных - с вниманием и любовью</Text>
        </View>
        <View style={styles.row}>
          <Image source={require('../../../public/money_tree.png')} resizeMode='contain' style={{flex: 1,
            height: 60,
            width: 60,
            opacity: 0.9,
            alignSelf: 'center',
            marginRight: 20,}} />
          <Text category='s1' style={styles.rowText}>Даем 3% бонусов к каждой покупке</Text>
        </View>
      </View>
      
      {!context.user && <Button category='primary' style={{margin: 20}} iconName='arrow-forward-circle-outline'  onPress={() => {
        navigation.navigate('Login')
      }}>Войти</Button>}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row', 
    alignItems: 'center', 
    marginBottom: 10,
  },
  image: {
    flex: 1,
    height: 60,
    width: 60,
    alignSelf: 'center',
    marginRight: 20,
  },
  rowText: {
    flex: 4,
    color: 'white'
  },
})