import React, { useEffect, useContext, useState }  from 'react'
import { View, ScrollView, StyleSheet, ActivityIndicator, TouchableOpacity, Dimensions, Image, Platform, Linking } from 'react-native'
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Text from '../../components/Text'
import Button from '../../components/Button'
import { Ionicons } from '@expo/vector-icons'
import colors from '../../utils.js/colors'
import { UserContext } from '../../global/UserProvider'
import TextInput from '../../components/TextInput'
import RadioButton from '../../components/Radio'
import { TotalsView } from './CartScreen'
import { ChooseStoreModal } from '../Home/HomeScreen'
import { saveOrder, updateUserInfo } from '../../global/Firebase/firebase'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Analytics from 'expo-firebase-analytics'
import PhoneInput from 'react-native-phone-input'

export default function CheckoutScreen( { navigation, route } ) {

  const [context, setContext] = useContext(UserContext)
  const [deliveryMethod, setDeliveryMethod] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [chooseStoreModalVisible, setChooseStoreModalVisible] = useState(false)
  const [name, setName] = useState(context.user?.displayName || '')
  // const [phone, setPhone] = useState(context.user?.phoneNumber || '')
  const [street, setStreet] = useState(context.user?.addresses?.[0]?.street || '')
  const [house, setHouse] = useState(context.user?.addresses?.[0]?.house || '')
  const [apartment, setApartment] = useState(context.user?.addresses?.[0]?.apartment || '')
  const [comment, setComment] = useState(context.user?.addresses?.[0]?.comment || '')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const phoneInput = React.createRef()

  return (
    <>
      <ScrollView style={{flex: 1, paddingHorizontal: 20}} extraScrollHeight={20} viewIsInsideTabBar={true} keyboardShouldPersistTaps='always'> 
        {/* <Text category='h4' style={styles.marginVerticalLarge}>Метод доставки</Text> */}
        {/* <RadioButton style={styles.marginVerticalSmall} selected={deliveryMethod == 0} text='Доставка' onPress={() => setDeliveryMethod(0)} />
        <RadioButton style={styles.marginVerticalSmall} selected={deliveryMethod == 1} text='Самовывоз' onPress={() => setDeliveryMethod(1)} /> */}
        {/* <Text category='h5' style={styles.marginVerticalLarge}>Метод оплаты</Text>
        <RadioButton style={styles.marginVerticalSmall} selected={paymentMethod == 0} text='Картой' onPress={() => setPaymentMethod(0)} />
        <RadioButton style={styles.marginVerticalSmall} selected={paymentMethod == 1} text='Каспи' onPress={() => setPaymentMethod(1)} />
        <RadioButton style={styles.marginVerticalSmall} selected={paymentMethod == 2} text='Наличными' onPress={() => setPaymentMethod(2)} /> */}
        <Text category='h4' style={styles.label}>Телефон</Text>
        <PhoneInput 
          ref={phoneInput}
          autoFormat={true}
          initialCountry='kz'
          style={{
            height: 50,
            backgroundColor: '#f7f9fc',
            borderRadius: 4,
            borderColor: '#e4e9f2',
            borderWidth: 1,
            paddingLeft: 20,
            textAlignVertical: 'center',
          }} 
          textStyle={{height: 50, paddingRight: 20}}
          cancelText={'Отмена'}
          confirmText={'Подтвердить'}
          onPressFlag={Platform.OS === 'web' ? () => {} : null}
          allowZeroAfterCountryCode={true}
        />
        {/* <TextInput
          value={phone}
          onChangeText={setPhone}
          returnKeyType='done'
          keyboardType='phone-pad'
          style={styles.nameInput}
          inputStyle={{borderRadius: 5}}
        /> */}
        <Text category='h4' style={styles.label}>Имя</Text>
        <TextInput
          value={name}
          onChangeText={setName}
          returnKeyType='done'
          autoCapitalize='words'
          style={styles.nameInput}
          inputStyle={{borderRadius: 5}}
        />
        {/* { deliveryMethod == 0 && (
          <View style={{marginTop: 20}}>
            <Text category='h4' style={styles.label}>Адрес</Text>
            <Text category='h5' style={styles.label}>Улица</Text>
            <TextInput
              value={street}
              onChangeText={setStreet}
              autoCapitalize='words'
              returnKeyType='done'
              style={styles.nameInput}
              inputStyle={{borderRadius: 5}}
            />
            <Text category='h5' style={styles.label}>Дом</Text>
            <TextInput
              value={house}
              onChangeText={setHouse}
              autoCapitalize='sentences'
              returnKeyType='done'
              style={styles.nameInput}
              inputStyle={{borderRadius: 5}}
            />
            <Text category='h5' style={styles.label}>Кв/Офис</Text>
            <TextInput
              value={apartment}
              onChangeText={setApartment}
              autoCapitalize='sentences'
              returnKeyType='done'
              style={styles.nameInput}
              inputStyle={{borderRadius: 5}}
            />
            <Text category='h5' style={styles.label}>Комментарий к адресу</Text>
            <TextInput
              value={comment}
              onChangeText={setComment}
              autoCapitalize='sentences'
              returnKeyType='done'
              style={styles.nameInput}
              inputStyle={{borderRadius: 5}}
            />
          </View>
        )} */}
        {error && <Text style={{marginTop: 30}} danger>Ошибка: Пожалуйста укажите ваш номер телефона</Text>}
        {loading ? (<View style={[{marginTop: 30, alignItems: 'center'}]}><ActivityIndicator /></View>) : (<Button  category='primary' 
          iconName='checkmark-circle-outline' 
          style={{marginTop: 30}}
          onPress={async () => {
            let phone = phoneInput.current.getValue()
            if (phone && phone !== '') {
              setLoading(true)
              setError(false)
              await saveOrder(context.organizationId, {
                clientId: (context.user?.id || ''),
                cart: context.cart,
                name,
                phone,
                // address: (street + ', ' + house + ', ' + apartment + ', ' + comment),
                // deliveryMethod,
                // store: context.store,
                date: new Date(),
                isProcessed: false,
              })
              if (context.user?.id) {
                updateUserInfo(context.user.id, {
                  addresses: [{street, house, apartment, comment}],
                  displayName: name
                })
                setContext((context) => ({...context, 
                  user: {...context.user, addresses: [{street, house, apartment, comment}], displayName: name}, 
                }))
              }
              setContext((context) => ({...context, cart: []}))
              AsyncStorage.setItem('cart'+context.organizationId, JSON.stringify([]))
              // Analytics.logEvent('FinishOrder')
              setLoading(false)
              navigation.navigate('Success')
            } else {
              setError(true)
            }
          }}
        >
          Оформить заказ
        </Button>)}
        <Text hint style={{marginTop: 10}}>* После офомления заказа с вами свяжется менеджер для уточнения деталей и проведения оплаты</Text>
        <View style={{height: 20}}></View>
      </ScrollView>
      {/* <TotalsView cart={context.cart} nextAction={() => {}} nextActionText='Оформить заказ' store={context.store} /> */}
    </>
  )

}

const styles = StyleSheet.create({
  marginVerticalLarge: {
    marginTop: 20,
    marginBottom: 5,
  },
  marginVerticalSmall: {
    marginVertical: 10,
  },
  label: {
    marginTop: 10,
    marginBottom: 5,
  },
})