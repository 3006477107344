import React, { useEffect, useContext, useState }  from 'react'
import { View, StyleSheet, ActivityIndicator, TouchableOpacity, Image, ScrollView } from 'react-native'
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Button from '../../components/Button'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import { UserContext } from '../../global/UserProvider'
import { Ionicons } from '@expo/vector-icons'
import colors from '../../utils.js/colors'
import { firebaseAuth, updateUserInfo } from '../../global/Firebase/firebase'
import firebase from 'firebase/app'
import * as Analytics from 'expo-firebase-analytics'

export default function UpdateProfileScreen( { navigation } ) {

  const [context, setContext] = useContext(UserContext)
  const [name, setName] = useState(context.user.displayName || '')
  const [photoURL, setPhotoURL] = useState(context.user.photoURL)
  const [street, setStreet] = useState(context.user.addresses?.[0]?.street || '')
  const [house, setHouse] = useState(context.user.addresses?.[0]?.house || '')
  const [apartment, setApartment] = useState(context.user.addresses?.[0]?.apartment || '')
  const [comment, setComment] = useState(context.user.addresses?.[0]?.comment || '')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  
  return (
    <ScrollView style={styles.container} extraScrollHeight={20} viewIsInsideTabBar={true} keyboardShouldPersistTaps='always'>
      <TouchableOpacity style={styles.imagePlaceholder}>
        { photoURL ? 
          <>
            {/* { Platform.OS === 'web' && 
              (<NextImage src={photoURL} layout='fill' />) 
            }
            { Platform.OS !== 'web' && 
              (<Image source={{ uri: photoURL }} resizeMode='contain' style={styles.image} />)
            } */}
            <Image source={{ uri: photoURL }} resizeMode='contain' style={styles.image} />
          </>
          : (
            <Ionicons name='person-circle' size={200} color={colors.gray} style={{alignSelf: 'center'}} />
          )
        }
      </TouchableOpacity>
      
      <Text category='h5' style={styles.label}>Имя</Text>
      <TextInput
        value={name}
        onChangeText={setName}
        autoCapitalize='words'
        returnKeyType='done'
        style={styles.nameInput}
        inputStyle={{borderRadius: 5}}
      />
      <Text category='h4' style={styles.label}>Адрес</Text>
      <Text category='h5' style={styles.label}>Улица</Text>
      <TextInput
        value={street}
        onChangeText={setStreet}
        autoCapitalize='sentences'
        returnKeyType='done'
        style={styles.nameInput}
        inputStyle={{borderRadius: 5}}
      />
      <Text category='h5' style={styles.label}>Дом</Text>
      <TextInput
        value={house}
        onChangeText={setHouse}
        autoCapitalize='sentences'
        returnKeyType='done'
        style={styles.nameInput}
        inputStyle={{borderRadius: 5}}
      />
      <Text category='h5' style={styles.label}>Кв/Офис</Text>
      <TextInput
        value={apartment}
        onChangeText={setApartment}
        autoCapitalize='sentences'
        returnKeyType='done'
        style={styles.nameInput}
        inputStyle={{borderRadius: 5}}
      />
      <Text category='h5' style={styles.label}>Комментарий к адресу</Text>
      <TextInput
        value={comment}
        onChangeText={setComment}
        autoCapitalize='sentences'
        returnKeyType='done'
        style={styles.nameInput}
        inputStyle={{borderRadius: 5}}
      />
      { error && <Text danger style={styles.error}>{error}</Text>}
      { success && <Text success style={styles.error}>Успешно</Text>}
      { loading ? (
        <View style={{alignItems: 'center', marginTop: 20,}}><ActivityIndicator /></View>
      ) : (
        success ? (
          <Button style={{marginTop: 20}} onPress={() => {
            navigation.goBack()
          }}>
            Назад
          </Button>
        ) : (
          <Button style={{marginTop: 20}} onPress={async () => {
            setLoading(true)
            try {
              // await firebaseAuth.currentUser.updateProfile({
              //   displayName: name,
              //   photoURL: photoURL
              // })
              // Analytics.logEvent('UpdateProfile')
              await updateUserInfo(context.user.id, {
                displayName: name,
                photoURL: photoURL,
                addresses: [{street, house, apartment, comment}]
              })
              setContext((context) => ({...context, user: {...context.user, displayName: name, photoURL, addresses: [{street, house, apartment, comment}] }}))
              setLoading(false)
              setError(null)
              setSuccess(true)
            } catch (error) {
              setError(error.message)
              setLoading(false)
            }
          }}>
            Сохранить
          </Button>
        )
      )}
      {/* <View style={{height: 10}}></View> */}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
  },
  imagePlaceholder: {
    height: 200,
    width: 200,
    alignSelf: 'center',
    borderRadius: 100,
  },
  nameInput: {
    marginBottom: 10,
  },
  label: {
    marginTop: 10,
    marginBottom: 5,
  },
  error: {
    marginBottom: 10,
  }
})