import React from 'react'
import { StatusBar } from 'expo-status-bar'
import { UserProvider } from './global/UserProvider'
import TabNavigator from './global/NavigationMobile/TabNavigator'
import * as Sentry from 'sentry-expo'

Sentry.init({
  dsn: 'https://d698ed692a3645d5b91f300491c925e9@o1236871.ingest.sentry.io/6504088',
  enableInExpoDevelopment: false,
  debug: false, 
})


export default function App() {
  return (
    <UserProvider>
      <TabNavigator />
      <StatusBar style='auto' />
    </UserProvider>
  )
}