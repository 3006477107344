import React from 'react'
import { TouchableOpacity, Text, StyleSheet } from 'react-native'
import colors from '../utils.js/colors'
import { Ionicons } from '@expo/vector-icons'

export default function Button({ children, onPress, category='regular', style, iconName }) {
  const categories = {
    regular: {
      borderColor: 'black',
      borderWidth: 1,
      borderRadius: 4,
      fontColor: 'black',
      paddingVertical: 15,
      paddingHorizontal: 20,
      backgroundColor: colors.lightGray,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 2,
    }, 
    primary: {
      backgroundColor: colors.primary,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 2,
      fontColor: 'white',
      borderRadius: 4,
      paddingVertical: 15,
      paddingHorizontal: 20,
    },
    danger: {
      backgroundColor: colors.danger,
      fontColor: 'white',
      borderRadius: 4,
      paddingVertical: 15,
      paddingHorizontal: 20,
    },
    tag: {
      // backgroundColor: colors.primary,
      borderRadius: 15,
      borderColor: colors.primary,
      borderWidth: 1,
      fontColor: colors.primary,
      paddingVertical: 5,
      paddingHorizontal: 10,
    },
    selectedTag: {
      backgroundColor: colors.primary,
      borderRadius: 15,
      fontColor: 'white',
      paddingVertical: 5,
      paddingHorizontal: 10,
    },
    outline: {

    }
  }

  return (
    <TouchableOpacity style={[styles.button, categories[category], {justifyContent: iconName ? 'space-between' : 'center'},  style]} onPress={onPress}>
      <Text style={[styles.text, {color: categories[category].fontColor}]}>
        {children}
      </Text>
      {iconName && <Ionicons name={iconName} size={20} color={categories[category].fontColor} />}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '600',
  }
})