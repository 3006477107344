import React, { useEffect, useContext, useState }  from 'react'
import { View, StyleSheet, FlatList, ActivityIndicator } from 'react-native'
import { getUserSales } from '../../global/Firebase/firebase'
import { UserContext } from '../../global/UserProvider'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import Text from '../../components/Text'
import { Ionicons } from '@expo/vector-icons'
import colors from '../../utils.js/colors'

export default function NewScreen( { navigation, route } ) {
  const [context, setContext] = useContext(UserContext)
  const [orders, setOrders] = useState(null)

  useEffect(() => {
    getOrders()
  }, [])

  const getOrders = async () => {
    const newOrders = await getUserSales(context.user.id)
    setOrders(newOrders)
  }

  const renderItem = ({ item }) => (
    <View style={{backgroundColor: 'white', padding: 10, borderRadius: 10, marginVertical: 5}}>
      <Text category='h6'><Ionicons name='calendar-outline' size={18} color='black' />{' ' + format(item.saleDate.toDate(), "d/M/y H:mm", {locale: ru}) }</Text>
      <Text category='h6'><Ionicons name='basket-outline' size={18} color='black' />{ item.purchaseMethod == 0 ? ' Покупка в магазине' : ' Доставка' }</Text>
      <Text category='h6'><Ionicons name='pricetags-outline' size={18} color='black' />{' ' + item.finalTotalCartPrice + context.currency }</Text>
      <Text category='h6' style={{color: colors.success}}><Ionicons name='gift-outline' size={18} color={colors.success} />{' ' + item.bonusAccumulation + context.currency }</Text>
      <Text category='h5' style={{marginTop:10}}>Товары:</Text>
      { item.cartItems.map((cartItem, cartItemIndex) => (
        <View key={cartItem+cartItemIndex} style={{flexDirection: 'row', alignItems: 'center'}}> 
          <Text style={{flex: 5}}>{cartItem.name}</Text>
          <Text style={{flex: 1}}>{cartItem.quantity.toFixed(1)}</Text>
          <Text style={{flex: 1}}>{cartItem.finalItemPrice.toFixed() + context.currency}</Text>
        </View>
      )) }

    </View>
  )

  // const renderItem = ({ item }) => (
  //   <View style={{backgroundColor: 'white', padding: 10, borderRadius: 10, margin: 10}}>
  //     <Text category='h6'><Ionicons name='calendar-outline' size={18} color='black' />{' ' + format(item.date.toDate(), "d/M/y H:m", {locale: ru}) }</Text>
  //     { item.deliveryMethod == 1 && <Text category='h6'><Ionicons name='ios-map-outline' size={18} color='black' />{' Самовывоз с ' + context.stores[item.store].name }</Text> }
  //     { item.deliveryMethod == 0 && <Text category='h6'><Ionicons name='ios-map-outline' size={18} color='black' />{' ' + item.address}</Text> }
  //     <Text category='h6'><Ionicons name='call-outline' size={18} color='black' />{' ' + item.phone}</Text>
  //     <Text category='h5' style={{marginTop:10}}>Товары:</Text>
  //     { item.cart.map((cartItem, cartItemIndex) => (
  //       <View key={cartItem+cartItemIndex} style={{flexDirection: 'row', alignItems: 'center'}}> 
  //         <Text style={{flex: 5}}>{cartItem.name}</Text>
  //         <Text style={{flex: 1}}>{cartItem.quantity.toFixed(1)}</Text>
  //         <Text style={{flex: 1}}>{(cartItem.salePrices[item.store] * cartItem.quantity).toFixed()}</Text>
  //       </View>
  //     )) }

  //   </View>
  // )
  
  return (
    <View style={{flex: 1}}>
      { 
        orders 
          ? 
            <FlatList 
              data={orders}
              renderItem={renderItem}
              keyExtractor={item => item.id}
              style={{flex: 1, padding: 20}}
            />
          : 
            <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}><ActivityIndicator /></View>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  
})