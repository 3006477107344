import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore, collection, doc, setDoc, getDoc, getDocs, addDoc, onSnapshot, query, where, updateDoc, orderBy } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { firestoreFBConfig, authFBConfig } from './firebaseConfig'
import AsyncStorage from '@react-native-async-storage/async-storage'

// Initialize Firebase Apps - one for firestore, second for authentication
let firestoreFBInstance 
if (!firestoreFBInstance?.apps?.length) {
  firestoreFBInstance = initializeApp(firestoreFBConfig)
}

// firestoreFBInstance.firestore().settings({
//   cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
//   ignoreUndefinedProperties: true,
// })

let authFBInstance
if (!authFBInstance?.apps?.length) {
  authFBInstance = initializeApp(authFBConfig, 'auth')
}

export const auth = getAuth(authFBInstance)

export const db = getFirestore(firestoreFBInstance)

export const storage = getStorage(firestoreFBInstance)

export const loginWithEmail = (email, password) =>
  auth.signInWithEmailAndPassword(email, password)

export const logout = () => auth.signOut()

export const passwordReset = email => auth.sendPasswordResetEmail(email)

export const getUserInfo = async (phone, organizationId) => {
  const querySnapshot = await getDocs(query(collection(db, 'organizations/'+organizationId+'/clients'), where('phoneNumber', '==', phone)))
  if (querySnapshot.size > 0) {
    var client = querySnapshot.docs[0].data()
    client.id = querySnapshot.docs[0].id
    client.ref = querySnapshot.docs[0].ref
    return client
  } else {
    return null
  }
}

export const updateUserInfo = async (id, info, organizationId) => {
  updateDoc(doc(db, 'organizations/'+organizationId+'/clients/'+id), info)
}

export const getProducts = async (orgId, store) => {
  const querySnapshot = await getDocs(query(collection(db, 'organizations/'+orgId+'/products'), where('isHiddenInNewSale', '==', false), orderBy('name')))
  var flatDocs = []
  querySnapshot.forEach(function(doc) {
    var product = doc.data()
    if (product.activeInStore[store]) {
      product.id = doc.id
      product.ref = doc.ref
      flatDocs.push(product)
    }
  })
  return flatDocs
}

export const getProductsByCategory = async (orgId, categoryId, store) => {
  const querySnapshot = await getDocs(query(collection(db, 'organizations/'+orgId+'/products'), where('categoryID', '==', categoryId), where('isHiddenInNewSale', '==', false), orderBy('name')))
  var flatDocs = []
  querySnapshot.forEach(function(doc) {
    var product = doc.data()
    if (product.activeInStore[store]) {
      product.id = doc.id
      product.ref = doc.ref
      flatDocs.push(product)
    }
  })
  return flatDocs
}

export const getLocalCart = async (orgId) => {
  const cart = await AsyncStorage.getItem('cart'+orgId)
  if (cart) {
    return JSON.parse(cart)
  } else {
    return []
  }
}

export const saveOrder = async (orgId, order) => {
  await addDoc(collection(db, 'organizations/'+orgId+'/orders'), order)
}

export const getUserOrders = async (userId) => {
  const querySnapshot = await db.collection('clients').doc(userId).collection('orders').orderBy('date', 'desc').get()
  var flatDocs = []
  querySnapshot.forEach(function(doc) {
    var product = doc.data()
    product.id = doc.id
    product.ref = doc.ref
    flatDocs.push(product)
  })
  return flatDocs
}

export const getUserSales = async (clientId) => {
  const querySnapshot = await db.collection('sales').where('clientId', '==', clientId)
    // .orderBy('date', 'desc')
    .get()
  var flatDocs = []
  querySnapshot.forEach(function(doc) {
    var product = doc.data()
    product.id = doc.id
    product.ref = doc.ref
    flatDocs.push(product)
  })
  return flatDocs
}


