import React, { useEffect, useContext, useState }  from 'react'
import { View, ScrollView, StyleSheet, ActivityIndicator } from 'react-native'
import ProductGrid, { HORIZONTAL_PADDING } from '../../components/ProductGrid'
import Text from '../../components/Text'
import { UserContext } from '../../global/UserProvider'
import { db, getProductsByCategory, getLocalCart } from '../../global/Firebase/firebase'
import { doc, getDoc } from 'firebase/firestore'
import HeaderIconButton from '../../components/HeaderIconButton'
import { useLinkTo } from '@react-navigation/native'

export default function ProductCategoryScreen( { navigation, route } ) {

  const productCategory = route.params?.productCategory
  const orgId = route.params?.orgId
  const [context, setContext] = useContext(UserContext)
  const [allProducts, setAllProducts] = useState(null)
  const [filteredProducts, setFilteredProducts] = useState(null)
  const [descriptionText, setDescriptionText] = useState()
  const linkTo = useLinkTo()

  useEffect(() => {
    // get all product tags if not loaded before to get names
    if (!context.productCategories?.length) {
      getDoc(doc(db, 'organizations/'+orgId+'/productCategories/'+productCategory)).then(doc => {
        navigation.setOptions({
          title: doc.data().name
        })
        setDescriptionText(doc.data().descriptionText)
      })
    } else {
      for (const [index, category] of context.productCategories.entries()) {
        if (category.id === productCategory) {
          navigation.setOptions({
            title: category.name
          })
          setDescriptionText(category.descriptionText)
        }
      }
    }
    getProducts()
  }, [productCategory])

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <HeaderIconButton
          onPress={() => {
            linkTo('/'+orgId)
          }}
          iconName='arrow-back-outline'
        />
      ),
    })
  }, [])

  const getProducts = async () => {
    let store = context.store
    if (!context.store) {
      let orgDetail = await getDoc(doc(db, 'organizations/'+orgId))
      setContext(context => ({...context, 
        organizationId: orgId, 
        currency: (orgDetail.data().currency.shortText ? orgDetail.data().currency.shortText : orgDetail.data().currency.text), 
        store: orgDetail.data().storeForMarketplace, 
        disableNotInStockProducts: orgDetail.data().disableNotInStockProducts,
      }))
      store = orgDetail.data().storeForMarketplace
    }
    const products = await getProductsByCategory(orgId, productCategory, store)
    setAllProducts(products)
    setFilteredProducts(products)
    let localCart = await getLocalCart(orgId)
    setContext(context => ({...context, cart: localCart}))
  }
  
  return (
    <ScrollView style={styles.container}>

      { descriptionText && descriptionText.length > 0 && (
        <View style={styles.descriptionTextView}>
          <Text style={styles.descriptionText}>{descriptionText}</Text>
        </View>
      )}
      
      { filteredProducts
        ? 
          filteredProducts.length > 0
          ?
            <ProductGrid products={filteredProducts} store={context.store} currency={context.currency} disableNotInStockProducts={context.disableNotInStockProducts} />
          :
            <Text>Не найдено</Text>
        : 
          <ActivityIndicator style={styles.marginVertical} />
      }

      <View style={{marginBottom: 50}}></View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: HORIZONTAL_PADDING,
    paddingVertical: 20,
  },
  marginVertical: {
    marginVertical: 10,
  },
  categoriesFilter: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  categoryTag: {
    marginRight: 5,
    marginBottom: 5,
  },
  descriptionTextView: {
    borderRadius: 5, 
    backgroundColor: 'white',
    marginBottom: 20,
    padding: 10,
  },
  descriptionText: {
    
  }
})