import React, { useState, createContext, useEffect, useRef } from 'react'
import { auth, db, getUserInfo, getLocalCart } from './Firebase/firebase'
import { onAuthStateChanged } from "firebase/auth"
import { collection, doc, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore"
import * as Analytics from 'expo-firebase-analytics'
import * as Notifications from 'expo-notifications'
import Constants from 'expo-constants'
import { Platform } from 'react-native'

export const UserContext = createContext({})

export const UserProvider = ({ children }) => {

  const [state, setState] = useState({
    user: null,
    organizationId: null,
    store: null, 
    currency: 'тг', 
    cart: [],
    // productsArray: [],
    // productsWithCategories: [],
    productsDict: {},
    productCategories: [],
    slides: [],
    activeOrders: [],
    unsubscribeActiveOrders: null,
    stores: {}
  })

  const notificationListener = useRef();
  const responseListener = useRef();

  useEffect(() => {
    // loadProducts()
    const unsubscribeAuth = onAuthStateChanged(auth, async authUser => {
      setState(state => ({...state, user: authUser}))
      if (authUser) {
        loadUserInfo(authUser)
      }
      // Analytics.setUserId(authUser?.uid)
    })

    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      console.log(notification)
    })

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      console.log(response)
    })
    
    return () => {
      unsubscribeAuth()
      state.unsubscribeActiveOrders?.()
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    }
  }, [])

  const loadUserInfo = async (authUser) => {
    const phoneNumber = authUser.phoneNumber?.replace('+', '')
    const info = await getUserInfo(state.organizationId, phoneNumber)
    const pushToken = await registerForPushNotificationsAsync()
    if (info) {
      const updatedUserInfo = {...info, id: info.id, ref: info.ref}
      setState(state => ({...state, user: {...state.user, ...updatedUserInfo}, store: info.store}))
      updateDoc(info.ref, {pushToken, uid: authUser.uid, store: 'samal'})
    } else {
      const newUser = { 
        uid: authUser.uid, 
        store: 'samal', 
        phoneNumber: phoneNumber, 
        bonusValue: 0, 
        bonusLevel: 3,
        totalPurchasedValue: 0,
        registrationSource: 'app',
        registrationDate: new Date(),
        pushToken
      }
      await setDoc(doc(db, 'organizations/'+state.organizationId+'/clients/'+authUser.uid), newUser)
      const updatedUserInfo = {...newUser, id: authUser.uid}
      setState(state => ({...state, user: {...state.user, ...updatedUserInfo}, store: 'samal'}))
    }
    const unsubscribeActiveOrders = onSnapshot(query(collection(db, 'organizations/'+state.organizationId+'/orders'), where('clientId', '==', (info ? info.id : authUser.uid)), where('isProcessed', '==', false)), function(querySnapshot) {
      var docs = []
      querySnapshot.forEach(function(doc) {
        var order = doc.data()
        order.id = doc.id
        order.ref = doc.ref
        docs.push(order)
      })
      setState(state => ({...state, activeOrders: docs, unsubscribeActiveOrders}))
    })
  }

  async function registerForPushNotificationsAsync() {
    let token;
    if (Constants.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
    } else {
      alert('Must use physical device for Push Notifications');
    }
  
    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
  
    return token;
  }

  return (
    <UserContext.Provider value={[ state, setState ]}>
      {children}
    </UserContext.Provider>
  )
}  
