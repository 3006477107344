import React, { useEffect, useContext, useState }  from 'react'
import { View, ScrollView, StyleSheet, Image, ActivityIndicator } from 'react-native'
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Text from '../../../components/Text'
import TextInput from '../../../components/TextInput'
import Button from '../../../components/Button'
import { UserContext } from '../../../global/UserProvider'
import * as Analytics from 'expo-firebase-analytics'
import { PhoneAuthProvider, signInWithCredential } from 'firebase/auth'


export default function LoginScreen2( { navigation, route } ) {

  const [context, setContext] = useContext(UserContext)
  const { verificationId } = route.params
  const [verificationCode, setVerificationCode] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  
  const verifyAndLogin = async (text) => {
    setLoading(true)
    // Analytics.logEvent('LoginVerifyCode')
    try {
      const credential = PhoneAuthProvider.credential(
        verificationId,
        text
      );
      const userCred = await signInWithCredential(credential)
      // Analytics.logEvent('LoginSuccess')
      const userInfo = userCred.user
      setError(null)
      setLoading(false)
      if (context.cart?.length > 0) {
        navigation.reset({
          index: 0,
          routes: [{ name: 'CartStack' }],
        })
      } else {
        navigation.reset({
          index: 0,
          routes: [{ name: 'ProfileStack' }],
        })
      }
    } catch (err) {
      setError(`Error: ${err.message}`)
      setLoading(false)
    }
  }

  return (
    <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
      <Image source={require('../../../public/dumpling_on_skate_red.png')} resizeMode='contain' style={styles.image} />
      <Text category='h1' center style={[styles.marginVertical, styles.title]}>Полученный код</Text>
      <TextInput
        returnKeyType='done'
        autoFocus
        style={styles.marginVertical}
        editable={!!verificationId}
        placeholder="123456"
        textContentType='oneTimeCode'
        onChangeText={(text) => {
          setVerificationCode(text)
          if (text.length == 6) {
            verifyAndLogin(text)
          }
        }}
        onSubmitEditing={(text) => verifyAndLogin(text)}
      />
      {error && <Text danger>{error}</Text>}
      {loading ? (<View style={[styles.marginVertical, styles.loader]}><ActivityIndicator /></View>) : (<Button
        style={[styles.marginVertical, styles.button]}
        category='primary'
        iconName='arrow-forward-circle-outline'
        onPress={() => verifyAndLogin(verificationCode)}
      >Подтвердить</Button>) }
      <View style={{marginBottom: 50}}></View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  marginVertical: {
    marginVertical: 10,
  },
  title: {
    marginTop: 20,
  },
  image: {
    flex: 1,
    height: 200,
    width: 200,
    alignSelf: 'center',
  },
  loader: {
    alignItems: 'center',
  },
})