import React, { useEffect, useContext, useState }  from 'react'
import { View, StyleSheet, Image, ScrollView, Platform, ActivityIndicator } from 'react-native'
import Text from '../../../components/Text'
import TextInput from '../../../components/TextInput'
import Button from '../../../components/Button'
// import * as Analytics from 'expo-firebase-analytics'
import { getApp } from 'firebase/app'
import { FirebaseRecaptchaVerifierModal, FirebaseRecaptchaBanner } from 'expo-firebase-recaptcha'
import { PhoneAuthProvider, getAuth } from 'firebase/auth'


export default function LoginScreen( { navigation, route } ) {

  const recaptchaVerifier = React.useRef(null) // for mobile
  const [recaptchaToken, setRecaptchaToken] = useState(null) // for web
  const [phoneNumber, setPhoneNumber] = useState('+7')
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const app = getApp()
  const auth = getAuth()
  
  return (
    <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
      <Image source={require('../../../public/dumpling_on_skate_red.png')} resizeMode='contain' style={styles.image} />
      <Text category='h1' center style={[styles.marginVertical, styles.title]}>Введите пожалуйста ваш номер телефона</Text>
      <Text category='s1' center hint style={[styles.subtitle]}>На него будет выслан СМС-код для подтверждения</Text>
      <TextInput 
        value={phoneNumber}
        placeholder="+7 999 999 9999"
        returnKeyType='done'
        autoCompleteType="tel"
        keyboardType="phone-pad"
        textContentType="telephoneNumber"
        onChangeText={phoneNumber => setPhoneNumber(phoneNumber)}
        style={[styles.marginVertical, styles.input]}
        onSubmitEditing={async () => {
          setLoading(true)
          // Analytics.logEvent('LoginSendCode')
          try {
            // const newToken = recaptchaVerifier.current.verify()
            // console.log(newToken)
            // const applicationVerifier = new FirebaseRecaptchaVerifier(recaptchaToken)
            const phoneProvider = new PhoneAuthProvider(auth)
            const verificationId = await phoneProvider.verifyPhoneNumber(
              phoneNumber,
              recaptchaVerifier.current
              // (Platform.OS !== 'web' ? recaptchaVerifier.current : applicationVerifier)
            )
            navigation.navigate('Login2', {verificationId})
            setError(null)
            setLoading(false)
          } catch (err) {
            console.log(err)
            setError(`Error: ${err.message}`)
            setLoading(false)
          }
        }}
      />
      {error && <Text danger>{error}</Text>}
      {loading ? (<View style={[styles.marginVertical, styles.loader]}><ActivityIndicator /></View>) : (<Button
        style={[styles.marginVertical, styles.button]}
        category='primary'
        iconName='arrow-forward-circle-outline'
        onPress={async () => {
          setLoading(true)
          // Analytics.logEvent('LoginSendCode')
          try {
            // const newToken = recaptchaVerifier.current.verify()
            // console.log(newToken)
            // const applicationVerifier = new FirebaseRecaptchaVerifier(recaptchaToken)
            const phoneProvider = new PhoneAuthProvider(auth)
            const verificationId = await phoneProvider.verifyPhoneNumber(
              phoneNumber,
              recaptchaVerifier.current
              // (Platform.OS !== 'web' ? recaptchaVerifier.current : applicationVerifier)
            )
            navigation.navigate('Login2', {verificationId})
            setError(null)
            setLoading(false)
          } catch (err) {
            console.log(err)
            setError(`Error: ${err.message}`)
            setLoading(false)
          }
        }}
      >Отправить код</Button>)}
      <FirebaseRecaptchaVerifierModal
        // invisible={true}
        ref={recaptchaVerifier}
        firebaseConfig={app.options}
        // attemptInvisibleVerification={true}
        // androidHardwareAccelerationDisabled={true}
        style={styles.recaptcha}
      />
      <FirebaseRecaptchaBanner style={[styles.marginVertical, styles.recaptcha]} />
      <View style={{marginBottom: 50}}></View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  marginVertical: {
    marginVertical: 10,
  }, 
  title: {
    marginTop: 20,
    flex: 1,
  },
  subtitle: {
    flex: 1,
    marginBottom: 20,
  },
  image: {
    flex: 1,
    height: 200,
    width: 200,
    alignSelf: 'center',
  },
  button: {
    flex: 1,
  },
  recaptcha: {
    flex: 1,
  },
  loader: {
    alignItems: 'center',
  },
})