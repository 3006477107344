import React, { useEffect, useContext, useState, useRef }  from 'react'
import { View, ScrollView, StyleSheet, TouchableOpacity, TouchableHighlight, ActivityIndicator, Dimensions, Image, ImageBackground, SafeAreaView, Platform } from 'react-native'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import { db, getLocalCart } from '../../global/Firebase/firebase'
import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore"
import { UserContext } from '../../global/UserProvider'
import colors from '../../utils.js/colors'
import Constants from 'expo-constants'
import SearchScreen from './SearchScreen'
import * as Analytics from 'expo-firebase-analytics'
import MarketplaceMainScreen from './MarketplaceMainScreen'
import { useLinkTo } from '@react-navigation/native'

export const BORDER_RADIUS = 15

export const calculateWidth = (size) => {
  if (size === 1) {
    return '30.3%'
  } else if (size === 2) {
    return '47%'
  } else {
    return '97%'
  }
}

export default function HomeScreen( { navigation, route } ) {

  const [context, setContext] = useContext(UserContext)
  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)
  const [chooseStoreModalVisible, setChooseStoreModalVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const orgId = route.params?.orgId
  const linkTo = useLinkTo()

  useEffect(() => {
    navigation.setOptions({
      header: () => {}
    })
    if (!context.productCategories?.length && orgId) {
      var promises = []
      promises.push(getDocs(query(collection(db, 'organizations/'+orgId+'/productCategories'), orderBy('order'))))
      // promises.push(getDoc(doc(db, 'organizations/'+orgId+'/constants/slides')))
      promises.push(getDoc(doc(db, 'organizations/'+orgId)))
      resolvePromises(promises)
    }
  }, [orgId])

  const resolvePromises = async (promises) => {
    setIsLoading(true)
    const [ptQ, orgDetail] = await Promise.all(promises)
    // let slides = slidesQ.data()?.data
    var docs = []
    ptQ.forEach(function(doc) {
      var snap = doc.data()
      snap.id = doc.id
      snap.ref = doc.ref
      docs.push(snap)
    })
    
    if (orgDetail.data()?.linkEnabled) {
      let localCart = await getLocalCart(orgId)
      setTitle(orgDetail.data().name)
      setDescription(orgDetail.data().description)
      console.log(orgDetail.data())
      setContext(context => ({...context, 
        productCategories: docs, 
        organizationId: orgId, 
        currency: (orgDetail.data().currency.shortText ? orgDetail.data().currency.shortText : orgDetail.data().currency.text), 
        store: orgDetail.data().storeForMarketplace, 
        cart: localCart,
        disableNotInStockProducts: orgDetail.data().disableNotInStockProducts,
      }))
    } else {
      setTitle('Не найдено')
    }
    
    setIsLoading(false)
  }

  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      { orgId != null ? (
        <ScrollView 
          keyboardDismissMode={Platform.OS !== 'web' ? 'on-drag' : 'interactive'}
          style={{flex: 1}}
        >          
          <View 
            style={[{
              paddingLeft: '3%',
              width: calculateWidth(3)
            }, 
            Platform.OS === 'web' || Platform.OS === 'android' ? {
              marginTop: -40, paddingTop: 60
            } : {
              marginTop: -40, paddingTop: Constants.statusBarHeight
            }]}
          >
            <Text category='h1' style={{marginBottom: 10}}>{title}</Text>
            <Text style={{marginBottom: 15}}>{description}</Text>
            { context.productCategories?.length > 0 && (
              <TextInput
                style={{borderRadius: BORDER_RADIUS}}
                placeholder='Поиск'
                returnKeyType='search'
                autoCapitalize="none"
                autoCorrect={false}
                clearButtonMode="always"
                clearAction={() => setSearchQuery('')}
                value={searchQuery}
                onChangeText={setSearchQuery}
              />
            )}
            
          </View>
          
          { searchQuery.length == 0 
            ?
              <View style={{flex: 1, paddingLeft: '3%'}}>
                {
                  isLoading 
                  ?
                    <View style={{flex: 1, justifyContent: 'space-around' ,alignItems: 'center', paddingRight: '3%', marginTop: 20}}><ActivityIndicator /></View>
                  :
                    <View>
                      {/* <View style={{height: 150, marginVertical: 20, width: calculateWidth(3)}}>
                        <Swiper
                          loop
                          timeout={3}
                          controlsProps={{
                            dotsTouchable: true,
                            prevPos: 'left',
                            nextPos: 'right',
                            nextTitle: '>',
                            prevTitle: '<',
                          }}
                        >
                          { context.slides?.map((value, index) => (
                            <View key={value+index} style={[styles.slideContainer, {backgroundColor: value.backgroundColor}]}>
                              <Text style={{fontSize: 20, margin: 10, color: 'white', fontWeight: '600', alignSelf: 'center', textAlign: 'center', marginTop: 20}}>{value.title}</Text>
                              <Image source={value.imageURL && { uri: 'https://storage.googleapis.com/qarasay-kz.appspot.com/productTagImages/' + encodeURIComponent(value.imageURL) }} style={{height: 60, resizeMode: 'contain'}} />
                            </View>
                          ))}
                        </Swiper>
                      </View> */}
                      <View style={{flexDirection: 'row', flexWrap: 'wrap', marginTop: 20}}>
                        { context.productCategories.map((category, categoryIndex) => (
                          <TouchableOpacity key={categoryIndex.toString()} style={{height: 110, width: calculateWidth(1), backgroundColor: colors.peach, borderRadius: 15, marginBottom: '3%', marginRight: '3%', overflow: 'hidden'}} onPress={() => {
                            linkTo('/'+orgId+'/category/'+category.id)
                            // Analytics.logEvent('ViewProductTag', {
                            //   productTag: tile.name
                            // })
                          }}>
                            <Text style={{fontSize: 16, margin: 10, color: 'white', fontWeight: '500', textAlign: 'center'}}>{category.name}</Text>
                          </TouchableOpacity>
                        ))}
                      </View>
                    </View>
                }
              </View>
            :
              <SearchScreen style={{flex: 1, paddingLeft: '3%'}} query={searchQuery} />
          }

          <View style={{height: 50, backgroundColor: 'white'}}></View>
        </ScrollView>
      ) : (
        <MarketplaceMainScreen />
      )}
      {/* <ChooseStoreModal chooseStoreModalVisible={chooseStoreModalVisible} setChooseStoreModalVisible={setChooseStoreModalVisible} /> */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // height: Dimensions.get('screen').height - 100,
  },
  marginVertical: {
    marginVertical: 8,
  },
  marginHorizontal: {
    marginHorizontal: 8,
  },
  row: {
    flexDirection: 'row',
  },  
  categoryTitle: {
    marginTop: 15,
  },
  tile: {
    borderRadius: BORDER_RADIUS,
    marginHorizontal: 4,
    height: 100,
    overflow: 'hidden',
  }, 
  tileTitle: {
    color: 'white', 
    fontWeight: '600',
    marginHorizontal: 14, 
    marginTop: 11,
    fontSize: 15,
  },
  imageContainer: {
    height: 75, 
    alignSelf: 'flex-end'
  },
  slideContainer: {
    flex: 1,
    borderRadius: BORDER_RADIUS,
  },
})