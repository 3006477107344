import React, { useEffect, useContext }  from 'react'
import { View, StyleSheet, Image } from 'react-native'
import Text from '../../components/Text'
import Button from '../../components/Button'
import { UserContext } from '../../global/UserProvider'
import { useLinkTo } from '@react-navigation/native'

export default function SuccessScreen( { navigation, route } ) {
  const [context, setContext] = useContext(UserContext)
  const linkTo = useLinkTo()

  useEffect(() => {
    if (context.cart.length > 0) {
      navigation.popToTop()
    }
  })
  
  return (
    <View style={{flex: 1,
      padding: 20,
      justifyContent: 'center',}}>
      <Image source={require('../../public/smiling_dumplings_green.png')} style={styles.emptyCartImage} />
      <Text category='s2' hint style={[styles.emptyCartText]}>Ваш заказ принят. В ближайшее рабочее время с вами свяжутся для уточнения деталей заказа 🙌</Text>
      <Button 
        iconName='arrow-forward-circle-outline' 
        style={[styles.emptyCartButton, styles.marginVertical]}
        onPress={() => {
          navigation.popToTop()
          linkTo('/'+context.organizationId)
        }}
      >На главную</Button>
    </View>
  )
}

const styles = StyleSheet.create({
  emptyCartText: {
    // marginTop: (Dimensions.get('screen').height-300) / 2,
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  emptyCartImage: {
    alignSelf: 'center',
    height: 200,
    width: 200,
  },
})