export default {
  primary: '#F6A4BC', // qarasay pink
  lightGray: '#F6F6F6', // background
  borderGray: '#d8d8d8',
  gray: '#828282',
  danger: '#ff3d71',
  success: '#4ce0b3',
  imageBackground: '#d8d8d8',
  lightPink: 'rgba(245, 200, 222, 0.5)',
  blue: '#8AC4FF',
  yellow: '#EADC90',
  green: '#ABDFC6',
  purple: '#D5BFEC',
  lightBlue: '#9CD5E3',
  peach: '#F7C5BC',
  orange: '#F5AF91',
  skin: '#E2BDC1',
}