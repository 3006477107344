import React from 'react'
import { TextInput as TextInputRN, StyleSheet, View, Text, Platform, TouchableOpacity } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

export default function TextInput( { style, inputStyle=null, caption=null, clearButtonMode=null, clearAction=null, ...otherProps } ) {

  if (caption) {
    return (
      <View style={[style]}>
        { Platform.OS === 'web' ? (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <TextInputRN style={[styles.input, inputStyle, {flex: 5}]} {...otherProps} />
            <TouchableOpacity style={{flex: 1, width: 20}} onPress={() => clearAction?.()}>
              <Ionicons name="location-outline" size={10} color="black"  />
            </TouchableOpacity>
          </View>
        ) : (
          <TextInputRN style={[styles.input, inputStyle]} clearButtonMode={clearButtonMode} {...otherProps} />
        )}
        <Text style={styles.caption}>{caption}</Text>
      </View>

    )
  } else {
    return (
        Platform.OS === 'web' || Platform.OS === 'android' ? (
          <View style={[style, styles.input, {flexDirection: 'row', alignItems: 'center'}]}>
            <TextInputRN style={[inputStyle, {flex: 1}]} {...otherProps} />
            { clearButtonMode === "always" && (
              <TouchableOpacity style={{width: 15}} onPress={() => clearAction?.()}> 
                <Ionicons name="close-circle-outline" size={15} color="black"  />
              </TouchableOpacity>
            )}
          </View>
        ) : (
          <TextInputRN style={[styles.input, style, inputStyle]} clearButtonMode={clearButtonMode} {...otherProps} />
        )
    )
  }
}

const styles = StyleSheet.create({
  input: {
    height: 50,
    backgroundColor: '#f7f9fc',
    borderRadius: 15,
    borderColor: '#e4e9f2',
    borderWidth: 1,
    paddingHorizontal: 10,
  },
  caption: {
    marginTop: 3,
  },
})