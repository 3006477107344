import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import Text from './Text'


export default function RadioButton({ selected, text, style, onPress }) {
  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <View style={[{
        height: 20,
        width: 20,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: '#000',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
      }]}>
        {
          selected ?
            <View style={{
              height: 8,
              width: 8,
              borderRadius: 4,
              backgroundColor: '#000',
            }}/>
            : null
        }
      </View>
      <Text category='s1'>{text}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  }
})