import React, { useState, useEffect, useRef } from 'react'
import { View, StyleSheet } from 'react-native'
import TextInput from './TextInput'
import { useSearchBox, useRefinementList, useCurrentRefinements, useClearRefinements } from 'react-instantsearch-hooks-web'

export default function SearchBox(props) {
  const { query, refine, clear, isSearchStalled } = useSearchBox(props)
  const [value, setValue] = useState(query);
  const inputRef = useRef(null);
  const { items, refine: refineList } = useRefinementList({ attribute: props.refinementAttribute })

  useEffect(() => {
    refineList(props.refinementValue)
  }, [])

  useEffect(() => {
    if (query !== value) {
      refine(value);
    }
  }, [value, refine]);

  useEffect(() => {
    // We bypass the state update if the input is focused to avoid concurrent
    // updates when typing.
    if (!inputRef.current?.isFocused() && query !== value) {
      setValue(query);
    }
  }, [query]);

  return (
    <View style={[styles.container, props.style]}>
      <TextInput
        // ref={inputRef}
        placeholder='Поиск'
        value={value}
        onChangeText={setValue}
        styles={styles.input}
        clearButtonMode="while-editing"
        autoCapitalize="none"
        autoCorrect={false}
        spellCheck={false}
      />
    </View>
  )
}


const styles = StyleSheet.create({
  container: {
    
  }
});
