import React  from 'react'
import { View, ScrollView, StyleSheet, TouchableOpacity } from 'react-native'
import Text from '../../components/Text'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch } from 'react-instantsearch-hooks-web'
import SearchResults from '../../components/SearchResults'
import SearchBox from '../../components/SearchBox'
import { useLinkTo } from '@react-navigation/native'
import colors from '../../utils.js/colors'

export default function MarketplaceMainScreen() {

  const searchClient = algoliasearch('7MHKPNKH3C', '3f2b30b3b5301be645af508389713c31')
  const linkTo = useLinkTo()


  const renderSearchItem = ({item, index}) => {
    return (
      <TouchableOpacity key={index.toString()} style={{height: 110, backgroundColor: colors.purple, borderRadius: 15, marginBottom: 20, overflow: 'hidden'}} onPress={() => {
        linkTo('/'+item.objectID)
        // Analytics.logEvent('ViewProductTag', {
        //   productTag: tile.name
        // })
      }}>
        <Text style={{fontSize: 16, margin: 10, color: 'white', fontWeight: '500', textAlign: 'center'}}>{item.name}</Text>
        <Text style={{fontSize: 13, margin: 10, color: 'white', fontWeight: '400', textAlign: 'center'}}>{item.description}</Text>
      </TouchableOpacity>

    )
  }
  
  return (
    <ScrollView style={styles.container}>
      <Text category='h1' style={{marginBottom: 10}}>ATLAS Marketplace</Text>
      <Text style={{marginBottom: 20}}>Пожалуйста выберите партнера для просмотра товаров и услуг</Text>
      <InstantSearch searchClient={searchClient} indexName="organizations">
        <SearchBox style={{marginBottom: 20}} refinementAttribute='marketplaceEnabled' refinementValue={true} />
        <SearchResults onSelect={() => {}} renderItem={renderSearchItem} />
      </InstantSearch>
      <View style={{marginBottom: 50}}></View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 20,
    backgroundColor: 'white'
  },
  marginVertical: {
    marginVertical: 10,
  },
  categoriesFilter: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  categoryTag: {
    marginRight: 5,
    marginBottom: 5,
  }
})