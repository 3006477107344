import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import HomeScreen from '../../screens/Home/HomeScreen'
import ProductCategoryScreen from '../../screens/Home/ProductCategoryScreen'

const HomeStackNavigator = createStackNavigator()

export default function HomeStack() {

  return (
    <HomeStackNavigator.Navigator screenOptions={{headerTintColor: '#000000'}}>
      <HomeStackNavigator.Screen name='Home' component={HomeScreen} options={{ title: 'Главная' }} />
      <HomeStackNavigator.Screen name='ProductCategory' component={ProductCategoryScreen} />
    </HomeStackNavigator.Navigator>
  )
}
