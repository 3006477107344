import React, { useState, useContext, useEffect } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import WelcomeScreen from '../../screens/Profile/Login/WelcomeScreen'
import LoginScreen from '../../screens/Profile/Login/LoginScreen'
import LoginScreen2 from '../../screens/Profile/Login/LoginScreen2'
import ProfileScreen from '../../screens/Profile/ProfileScreen'
import UpdateProfileScreen from '../../screens/Profile/UpdateProfileScreen'
import OrdersScreen from '../../screens/Profile/OrdersScreen'
import { UserContext } from '../UserProvider'

const ProfileStackNavigator = createStackNavigator()

export default function ProfileStack() {

  const [context, setContext] = useContext(UserContext)

  if (context.user) {
    return (
      <ProfileStackNavigator.Navigator screenOptions={{headerTintColor: '#000000'}} initialRouteName='Profile' >
        <ProfileStackNavigator.Screen name='Profile' component={ProfileScreen} options={{ title: 'Профиль' }} />
        <ProfileStackNavigator.Screen name='UpdateProfile' component={UpdateProfileScreen} options={{ title: 'Профиль' }} />
        <ProfileStackNavigator.Screen name='Orders' component={OrdersScreen} options={{ title: 'История покупок' }} />
        <ProfileStackNavigator.Screen name='Welcome' component={WelcomeScreen} options={{ title: 'О Qarasay' }}/>
      </ProfileStackNavigator.Navigator>
    )
  } else {
    return (
      <ProfileStackNavigator.Navigator screenOptions={{headerTintColor: '#000000'}}>
        <ProfileStackNavigator.Screen name='Welcome' component={WelcomeScreen} options={{ title: 'Профиль' }}/>
        <ProfileStackNavigator.Screen name='Login' component={LoginScreen} options={{ title: 'Авторизация' }}/>
        <ProfileStackNavigator.Screen name='Login2' component={LoginScreen2} options={{ title: 'Авторизация' }}/>
      </ProfileStackNavigator.Navigator>
    )
  }
  

}
