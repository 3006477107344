import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import CartScreen from '../../screens/Cart/CartScreen'
import CheckoutScreen from '../../screens/Cart/CheckoutScreen'
import SuccessScreen from '../../screens/Cart/SuccessScreen'

const CartStackNavigator = createStackNavigator()

export default function CartStack() {

  return (
    <CartStackNavigator.Navigator screenOptions={{headerTintColor: '#000000'}}>
      <CartStackNavigator.Screen name='Cart' component={CartScreen} options={{ title: 'Корзина' }} />
      <CartStackNavigator.Screen name='Checkout' component={CheckoutScreen} options={{ title: 'Заказ' }} />
      <CartStackNavigator.Screen name='Success' component={SuccessScreen} options={{ title: 'Заказ' }} />
    </CartStackNavigator.Navigator>
  )
}
