import React from 'react'
import { Text as TextRN } from 'react-native'
import colors from '../utils.js/colors'

export default function Text({ children, category='body', center=false, style, hint=false, danger=false, success=false }) {
  const categories = {
    body: {
      fontSize: 14
    }, 
    h1: {
      fontSize: 24,
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 22,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    h5: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    h6: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    navbar: {
      fontSize: 17,
      fontWeight: 'bold',
    },
    s1: {
      fontSize: 16,
    },
    s2: {
      fontSize: 18,
    },
  }

  const color = () => {
    if (hint) {
      return colors.gray
    }
    if (danger) {
      return colors.danger
    }
    if (success) {
      return colors.success
    }
    return 'black'
  }

  return (
    <TextRN style={[
      categories[category],
      {textAlign: center ? 'center' : 'auto'},
      {color: color()},
      style
    ]}>
      {children}
    </TextRN>
  )
}